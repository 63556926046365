import { NextPageWithLayout } from "./_app";
import { PropsWithChildren } from "react";
import Layout from "@/components/Layout/Layout";
import { getPage } from "@/data/page";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import Navigation from "@/modules/Navigation/Navigation.component";
import { getNavigation } from "@/data/navigation";
import MappedComponent from "@/modules/ComponentMap/ComponentMap";
import Footer, { FooterMeta } from "@/modules/Footer/Footer.component";
import Head from "next/head";
import MetaAttributes from "@/modules/MetaAttributes/MetaAttributes";
import Typo3PreviewBar from "@/modules/Typo3PreviewBar/Typo3PreviewBar";
import { getConfig } from "@/data/config";
import { Slot } from "utils/create-slots";
import { Config, PageProps } from "@/types/types";
import { PageContext } from "./[[...slug]]";

const Page: NextPageWithLayout<PropsWithChildren<PageProps>> = ({
  pageProps,
  navigation,
  footer,
  footerMeta,
  config,
  preview = false,
  editUrl,
}) => {
  if (!pageProps) return null;

  const {
    slug = "",
    breadcrumbs = [],
    content = {},
    meta = {},
    appearance = {},
  } = pageProps;

  return (
    <>
      <Head>
        <title>{meta.title}</title>
      </Head>
      <MetaAttributes meta={meta} slug={slug} />
      <PageContext.Provider value={{ config, breadcrumbs, meta, appearance }}>
        <Layout
          type={appearance.layout}
          transparentheader={appearance.transparentheader}
        >
          <Slot name="Navigation">
            <Navigation
              items={navigation}
              appearance={appearance}
              key={`nav-${appearance.transparentheader}`}
            />
          </Slot>
          <Slot name="Breadcrumbs">
            <Breadcrumbs items={breadcrumbs}>
              {breadcrumbs.map(({ link, title }) => (
                <Breadcrumbs.Item href={link} key="link">
                  {title}
                </Breadcrumbs.Item>
              ))}
            </Breadcrumbs>
          </Slot>
          {Object.entries(content).map(([key, value]: any[]) => (
            <Slot name={key} key={key}>
              {value.map((props, index) => (
                <MappedComponent {...props} key={index} />
              ))}
            </Slot>
          ))}
          <Slot name="Footer">
            <Footer items={footer} />
          </Slot>
          <Slot name="FooterMeta">
            <FooterMeta items={footerMeta} />
          </Slot>
        </Layout>
        {preview && <Typo3PreviewBar editUrl={editUrl} />}
      </PageContext.Provider>
    </>
  );
};

export async function getStaticProps({ preview = false, previewData }) {
  const pageProps: any = await getPage(["404-generated"], preview, previewData);
  const { navigation, footer, footerMeta } = await getNavigation();
  const config: Config = await getConfig();

  return {
    props: {
      pageProps,
      navigation,
      footer,
      footerMeta,
      config,
      preview,
      ...(preview
        ? {
            editUrl: `${process.env.NEXT_PUBLIC_API_URL}typo3/module/web/layout?id=${pageProps.id}`,
          }
        : {}),
    },
  };
}

export default Page;
